// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-project-js": () => import("./../src/templates/project.js" /* webpackChunkName: "component---src-templates-project-js" */),
  "component---src-templates-basic-text-js": () => import("./../src/templates/basicText.js" /* webpackChunkName: "component---src-templates-basic-text-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projekte-js": () => import("./../src/pages/projekte.js" /* webpackChunkName: "component---src-pages-projekte-js" */)
}

